import React, { useState, useEffect } from 'react';
import './search.css';
import CocktailResult from '../cocktailResult/cocktailResult';

const Search = () => {
  const [searchInput, setSearchInput] = useState('');
  const [data, setData] = useState(null);

  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchInput) {
        fetch(`https://www.thecocktaildb.com/api/json/v1/1/search.php?s=${searchInput}`)
          .then(response => response.json())
          .then(data => setData(data));
      } else {
        setData(null);
      }
    }, 1000);

    console.log('useEffect', data);

    return () => clearTimeout(timeoutId);
  }, [searchInput]);

  return (
    <div className="Search">
      <input
        type="text"
        className="search_input"
        placeholder="Enter your beverage..."
        onChange={handleSearch}
        value={searchInput}
      />
      <div className='cocktails'>
        {data ? (
          data.drinks ? (
            data.drinks.map(cocktail => (
              <CocktailResult key={cocktail.idDrink} data={cocktail} />
            ))
          ) : <p className='noRes'>No results found</p>
        ) : <p className='noRes'>No results found</p>}
      </div>
    </div>
  );
};

export default Search;
