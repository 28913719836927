import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './cocktailResult.css';
import { useState } from 'react';

const CocktailResult = (props) => (

  <div className="cocktailResult">
    <img className="imgDrink" src={props.data.strDrinkThumb} alt={props.data.strDrink} />
    <div className='text'>
      <div className='head'>
        <h2>{props.data.strDrink}</h2>
        <p>{props.data.strCategory}</p>
      </div>
      <div className='ingredients'>
        <h4>Ingredients</h4>
        <p>{props.data.strInstructions}</p>
        <div className='ingredientsList'>
          {Object.entries(props.data).map(([key, value]) => {
            if (key.startsWith('strIngredient') && value) {
              const measureKey = `strMeasure${key.slice(13)}`;
              const measure = props.data[measureKey];
              return (
                <p key={key}>
                  {value} - {measure}
                </p>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  </div>
);

CocktailResult.propTypes = {};

CocktailResult.defaultProps = {};

export default CocktailResult;
