import './App.css';
import Search from './components/search/search';

function App() {
  return (
    <div className="App">
      
      <header className="App-header">
        <Search />
      </header>

      
    </div>
  );
}

export default App;
